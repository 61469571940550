<template>
    <div class="zbStudentList">
        <div class="search_container searchArea">
            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                <el-form-item label="学年学期" >
                    <el-select v-model="searchForm.semester" filterable placeholder="请选择" style="width: 100%">
                        <el-option key="" label="全部" value=""></el-option>
                        <el-option v-for="item in acadyearTermList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="info" size ="mini" icon="search" @click='loadZbStudentList'>查询</el-button>
                </el-form-item>

            </el-form>
        </div>
        <div class="table_container">
            <el-table v-loading="loading" :data="tableData" style="width: 100%" align='center'>
                kg.exam_id, e.name exam_name, e.semester,  hc.name hard_name, tch.nick_name teacher_name, tch.name teacher_no, kg.student_no, kg.student_name
                <el-table-column prop="exam_id" label="考试号" align='center' ></el-table-column>
                <el-table-column prop="exam_name" label="考试名称" align='center' ></el-table-column>
                <el-table-column prop="semester" label="学年学期" width="200" align='center' ></el-table-column>
                <el-table-column prop="hard_name" label="班级" align='center' ></el-table-column>
                <el-table-column prop="teacher_name" label="阅卷组长" align='center' ></el-table-column>
                <el-table-column prop="teacher_no" label="组长账号" align='center' ></el-table-column>
                <el-table-column prop="student_no" label="学生学号" align='center' ></el-table-column>
                <el-table-column prop="student_name" label="学生姓名" align='center' ></el-table-column>

            </el-table>
            <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
        </div>
    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";

    export default {
        name: 'zbStudentList',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading:true, isShow:false, acadyearTermList: [],
                selZbStudent: null, selZbStudentDtl: null,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, semester: null, searchText: null},
            }
        },
        components:{
             Pagination
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
            this.loadZbStudentList();
            this.loadAcadyearTermList();
	   },
        methods: {

            loadZbStudentList(){
                this.loading = true;

                let search = { searchText: mUtils.searchText(this.searchForm.searchText), semester: mUtils.searchText(this.searchForm.semester), licenceId: this.userInfo.licenceId, orderBy: 'e.id desc'};
                let param = { controllerName: 'exam', methodName: '/zbList', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.tableData = data.result;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },
            loadAcadyearTermList(){
                this.acadyearTermList = [];
                let now = new Date();
                let year = parseInt(now.getFullYear());
                for(let i=(year-3); i<(year+2); i++){
                    this.acadyearTermList.unshift((i-1) + '-' + i + '第一学期');
                    this.acadyearTermList.unshift((i-1) + '-' + i + '第二学期');
                }
            },
            formatterStatus(item) {

                if(item.status == '00'){
                    return '正常';
                }else if(item.status == '01'){
                    return '禁用';
                }
                return '';

            },

            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadZbStudentList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadZbStudentList()
            },


        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


